import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "@apollo/react-hooks";
import { FEATURED_PRODUCTS, products } from "../queries-mutations/products";
import SingleProductSmall from "./SingleProductSmall";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { Translate } from "react-auto-translate";

const styles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.main,
    textAlign: "left",
    marginLeft: 20,
    fontSize: 30
  },
  container: { marginTop: 50 }
}));

function BestSellers(props) {
  const classes = styles();
  const { loading, error, data } = useQuery(FEATURED_PRODUCTS);
  if (data) {
    let best = data.featuredProducts
      .slice(0, 4)
      .map(
        product =>
          product.published &&
          product.price <= props.userInformation.duiten && (
            <SingleProductSmall
              newImg={product.images[0] ? product.images[0].new : false}
              img={product.images[0] ? product.images[0].location : null}
              name={product.name}
              price={product.price}
              id={product.id}
            />
          )
      );
    return (
      <Grid container className={classes.container}>
        <Grid xs={12}>
          <h2 className={classes.text}>
            <Translate>Populaire producten</Translate>
          </h2>
        </Grid>
        {best}
      </Grid>
    );
  } else if (loading) {
    return (
      <div>
        <CircularProgress color="primary" />
      </div>
    );
  } else if (error) {
    return (
      <div>
        <h2>
          <Translate>Er ging iets mis, probeer het later opnieuw</Translate>
        </h2>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { userInformation } = state;
  return { userInformation };
};

export default connect(mapStateToProps)(BestSellers);
