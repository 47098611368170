import React, { useState } from "react";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "@apollo/react-hooks";
import { SEARCH_PRODUCTS } from "../queries-mutations/products";
import SingleSearchResult from "./SingleSearchResult";
import Popper from "@material-ui/core/Popper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Translate } from "react-auto-translate";

const headerMenu = makeStyles(theme => ({
  searchContainer: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    dropShadow: 0,
    border: 0,
    backgroundColor: "#f6f6f6",
    borderRadius: 3
  },
  searchInput: {
    width: "90%",
    boxShadow: "none"
  },
  searchIcon: {},
  genericIcon: {
    fontSize: 30
  },
  hideOnMobile: {
    "@media (max-width: 768px)": {
      display: "none"
    }
  },
  hideOnDesktop: {
    "@media (min-width: 769px)": {
      display: "none"
    }
  },
  results: {
    padding: 15,
    marginTop: 10,
    minWidth: 300,
    maxWidth: "100vw !important",
    maxHeight: "80vh",
    overflowY: "scroll"
  }
}));

export default function SearchBar() {
  const classes = headerMenu();

  const [searchTerm, setSearchTerm] = useState("");
  const [focus, setFocus] = useState(null);

  const handleFocus = event => {
    setFocus(event.currentTarget);
  };

  const handleBlur = () => {
    setFocus(null);
  };

  const { loading, error, data } = useQuery(SEARCH_PRODUCTS, {
    variables: { q: searchTerm }
  });

  const open = Boolean(focus);
  const id = open ? "simple-popover" : undefined;

  const search = e => {
    setSearchTerm(e.target.value);
  };

  return (
    <Paper component="form" className={classes.searchContainer}>
      <InputBase
        className={classes.searchInput}
        placeholder="Zoeken..."
        disableElevation
        onChange={search}
        onFocus={handleFocus}
        onBlur={() => {
          setTimeout(function() {
            handleBlur();
          }, 500);
        }}
      />
      <IconButton
        type="submit"
        className={classes.searchIcon}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
      <Popper id={id} open={open} anchorEl={focus}>
        <Paper className={classes.results}>
          {loading ? (
            <CircularProgress />
          ) : searchTerm.length < 1 ? (
            <div>
              <Translate>Begin met zoeken</Translate>
            </div>
          ) : data ? (
            data.searchProducts.map(product => (
              <SingleSearchResult product={product} />
            ))
          ) : (
            <div>
              <Translate>Er ging iets fout</Translate>
            </div>
          )}
        </Paper>
      </Popper>
    </Paper>
  );
}
