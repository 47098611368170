import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import { Editor } from "@tinymce/tinymce-react";
import { tinyKey } from "../Keys/tiny";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CategoriesSelector from "./CategoriesSelector";
import objectInArray from "../helpers/objectInArray";
import { useMutation } from "@apollo/react-hooks";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {
  ADD_PRODUCT_TO_CATEGORY,
  CREATE_PRODUCT,
  REMOVE_PRODUCT_FROM_CATEGORY,
  UPDATE_PRODUCT,
  CREATE_IMAGE,
  ADD_IMAGE_TO_PRODUCT,
  REMOVE_IMAGE_FROM_PRODUCT,
  DELETE_PRODUCT,
} from "../queries-mutations/products";
import { editModalStyle } from "../styles/editModalStyle";
import ImageUploader from "react-images-upload";
import ImageUpload from "../helpers/ImageUpload";
import AdminImageDisplay from "./AdminImageDisplay";
import { trackPromise } from "react-promise-tracker";
import ConfirmationRequiredButton from "./ConfirmationRequiredButton";
import { PinDropRounded } from "@material-ui/icons";

export default function EditProductModal(props) {
  const [state, setState] = useState({
    name: props.product.name ? props.product.name : "",
    description: props.product.description ? props.product.description : "",
    price: props.product.price ? props.product.price : 0,
    published: !!props.product.published,
    code: props.product.code ? props.product.code : "",
    taxpercent : props.product.taxpercent ? props.product.taxpercent.toString() : "",
    order: props.product.order ? props.product.order : "",
    selectedCategories: props.product.categories
      ? props.product.categories
      : [],
    addedCategories: [],
    removedCategories: [],
    stock: props.product.stockAmount ? props.product.stockAmount : 0,
    images: [],
    removedImages: [],
    existingImages: props.product.images ? props.product.images : [],
    cartSuggestion: props.product.cartSuggestion
      ? props.product.cartSuggestion
      : false,
    featured: props.product.featured ? props.product.featured : false,
  });

  const classes = editModalStyle();

  const [updateProduct] = useMutation(UPDATE_PRODUCT);
  const [createProduct] = useMutation(CREATE_PRODUCT);
  const [addProductToCategory] = useMutation(ADD_PRODUCT_TO_CATEGORY);
  const [removeProductFromCategory] = useMutation(REMOVE_PRODUCT_FROM_CATEGORY);
  const [createImage] = useMutation(CREATE_IMAGE);
  const [addImageToProduct] = useMutation(ADD_IMAGE_TO_PRODUCT);
  const [removeImageFromProduct] = useMutation(REMOVE_IMAGE_FROM_PRODUCT);
  const [deleteProduct] = useMutation(DELETE_PRODUCT);

  let title = props.product.id ? "Product aanpassen" : "Nieuw product maken";

  const handleCheckChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handletaxChange = event => {
    setState({ ...state, [event.target.name]: event.target.value });
    console.log(state);
  };

  const handleTextChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleDescriptionChange = (content, editor) => {
    setState({ ...state, description: content });
  };

  const onDropImage = (image) => {
    setState({ ...state, images: image });
  };

  const removeImage = (imageId) => {
    setState({ ...state, removedImages: [...state.removedImages, imageId] });
  };

  const onSelectCategory = (data, context) => {
    setState({ ...state, selectedCategories: data });
    setState({
      ...state,
      addedCategories: [...state.addedCategories, context],
    });
  };

  const onRemoveCategory = (data, context) => {
    setState({ ...state, selectedCategories: data });
    setState({
      ...state,
      removedCategories: [...state.removedCategories, context],
    });
  };
  const [initiatedDelete, setInitiatedDelete] = useState(false);

  const initiateDelete = () => {
    setInitiatedDelete(true);
  };

  const cancelDelete = () => {
    setInitiatedDelete(false);
  };

  const confirmDelete = () => {
    deleteProduct({ variables: { id: props.product.id } });
    props.onClose();
  };

  const saveProduct = () => {
    let productID = "";
    state.removedCategories.forEach(function (removedCategory, removedIndex) {
      if (objectInArray(removedCategory, state.addedCategories) >= 0) {
        const addedIndex = objectInArray(
          removedCategory,
          state.addedCategories
        );
        setState({
          ...state,
          removedCategories: [
            ...state.removedCategories.slice(0, removedIndex),
            ...state.removedCategories.slice(removedIndex + 1),
          ],
          addedCategories: [
            ...state.addedCategories.slice(0, addedIndex),
            ...state.addedCategories.slice(addedIndex + 1),
          ],
        });
      }
    });
    //update product if it already exists
    if (!!props.product.id) {
      productID = props.product.id;
      updateProduct({
        variables: {
          id: props.product.id,
          name: state.name,
          description: state.description,
          price: parseInt(state.price),
          published: state.published,
          code: state.code,
          taxpercent: parseInt(state.taxpercent),
          order: parseInt(state.order),
          stockAmount: parseInt(state.stock),
          cartSuggestion: state.cartSuggestion,
          featured: state.featured,
        },
      });
      state.removedCategories.forEach(function (removedCategory) {
        removeProductFromCategory({
          variables: {
            productId: props.product.id,
            categoryId: removedCategory.id,
          },
        });
      });
      state.addedCategories.forEach(function (addedCategory) {
        addProductToCategory({
          variables: {
            productId: props.product.id,
            categoryId: addedCategory.id,
          },
        });
      });
    } else {
      //create product if it does not exist
      createProduct({
        variables: {
          name: state.name,
          description: state.description,
          price: parseInt(state.price),
          authorID: "ckbjguxgm000x0706su23aqjc", //TODO: User ID ophalen
          published: state.published,
          code: state.code,
          taxpercent: parseInt(state.taxpercent),
          order: parseInt(state.order),
          stockAmount: parseInt(state.stock),
          cartSuggestion: state.cartSuggestion,
          featured: state.featured,
        },
      }).then((r) => {
        productID = r.data.createProduct.id;
        state.addedCategories.forEach(function (addedCategory) {
          addProductToCategory({
            variables: {
              productId: r.data.createProduct.id,
              categoryId: addedCategory.id,
            },
          });
        });
        state.removedCategories.forEach(function (removedCategory) {
          removeProductFromCategory({
            variables: {
              productId: r.data.createProduct.id,
              categoryId: removedCategory.id,
            },
          });
        });
      });
    }

    //add images to product
    state.images.forEach((image) => {
      trackPromise(
        ImageUpload(image).then((r) => {
          createImage({
            variables: {
              location: r.data.name,
            },
          }).then((r) => {
            addImageToProduct({
              variables: {
                productId: productID,
                imageId: r.data.createImage.id,
              },
            });
          });
        })
      );
    });

    //remove images
    state.removedImages.forEach((image) => {
      removeImageFromProduct({
        variables: {
          productId: productID,
          imageId: image,
        },
      });
    });
    props.onClose();
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        className={classes.parent}
      >
        <Paper className={classes.prodmodalStyle}>
          <h2>{title}</h2>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              saveProduct();
            }}
          >
            <TextField
              variant="outlined"
              label="Naam"
              name="name"
              required
              defaultValue={state.name}
              className={classes.input}
              onChange={handleTextChange}
            />
            <br />
            <TextField
              variant="outlined"
              label="Code"
              name="code"
              required
              defaultValue={state.code}
              className={classes.input}
              onChange={handleTextChange}
              inputProps={{ min: 0 }}
            />
            <br />
            <TextField
              required
              variant="outlined"
              label="Prijs"
              name="price"
              type="number"
              defaultValue={state.price}
              className={classes.input}
              onChange={handleTextChange}
              inputProps={{ min: 0 }}
            />
            <br />
            <TextField
              required
              variant="outlined"
              label="Op voorraad"
              name="stock"
              type="number"
              defaultValue={state.stock}
              className={[classes.input, classes.lastInput]}
              onChange={handleTextChange}
              inputProps={{ min: 0 }}
            />
            <br />
            <FormControl className={classes.input}>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Tax percentage
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="taxpercent"
                required
                value={state.taxpercent}
                onChange={handletaxChange}
              >
                <FormControlLabel
                  value="9"
                  control={<Radio required={true}/>}
                  label="9%"
                />
                <FormControlLabel
                  value="21"
                  control={<Radio required={true}/>}
                  label="21%"
                />
              </RadioGroup>
            </FormControl>
            <br />
            <TextField
              required
              variant="outlined"
              label="sorteervolgorde"
              name="order"
              type="number"
              defaultValue={state.order}
              className={[classes.input, classes.lastInput]}
              onChange={handleTextChange}
              inputProps={{ min: 0 }}
            />
            <br />
            <FormControl className={classes.input}>
            <FormLabel
             className={classes.lastInput}
             id="demo-controlled-radio-buttons-group">
                categorieën
              </FormLabel>
            <CategoriesSelector
              onSelectCategory={onSelectCategory}
              onRemoveCategory={onRemoveCategory}
              selectedCategories={state.selectedCategories}
            />
            </FormControl>
            <br />
            <FormControlLabel
              className={[classes.input, classes.lastInput]}
              control={
                <Switch
                  checked={state.published}
                  onChange={handleCheckChange}
                  name="published"
                  label="Gepubliceerd"
                />
              }
              label="Gepubliceerd"
            />
            <FormControlLabel
              className={[classes.input, classes.lastInput]}
              control={
                <Switch
                  checked={state.featured}
                  onChange={handleCheckChange}
                  name="featured"
                  label="Uitgelicht"
                />
              }
              label="Uitgelicht"
            />
            <FormControlLabel
              className={[classes.input, classes.lastInput]}
              control={
                <Switch
                  checked={state.cartSuggestion}
                  onChange={handleCheckChange}
                  name="cartSuggestion"
                  label="Winkelwagen suggestie"
                />
              }
              label="Winkelwagen suggestie"
            />
            <Editor
              apiKey={tinyKey}
              value={state.description}
              init={{
                height: 250,
                marginTop: 10,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  // eslint-disable-next-line no-multi-str
                  "undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help",
              }}
              onEditorChange={handleDescriptionChange}
            />
            <AdminImageDisplay
              images={state.existingImages}
              removeImage={removeImage}
            />
            <ImageUploader
              withIcon={true}
              onChange={onDropImage}
              imgExtension={[".jpg", ".png"]}
              maxFileSize={10024 * 1024 * 1024}
              withPreview={true}
            />
            {props.product && (
              <ConfirmationRequiredButton
                initiated={initiatedDelete}
                cancel={cancelDelete}
                initiate={initiateDelete}
                confirm={confirmDelete}
                confirmationMessage={
                  "Weet je zeker dat je dit product wilt verwijderen? Alle gerelateerde informatie (inclusief bestellingen) word ook verwijderd. Dit is ten zeerste afgeraden, niet publiceren is vrijwel altijd een betere optie"
                }
                cancelMessage={"Annuleren"}
                confirmMessage={"Verwijderen"}
                initialMessage={"Verwijderen"}
              />
            )}
            <Button
              variant="contained"
              color="primary"
              className={classes.input}
              type="submit"
            >
              Opslaan
            </Button>
          </form>
        </Paper>
      </Modal>
    </div>
  );
}
