import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import { userRoutes } from "../routes/Users/constants";
import LazyLoad from "react-lazy-load";
import srcSetCreator from "../helpers/srcSetCreator";

const styles = makeStyles(theme => ({
  img: {
    maxWidth: "85%"
  },
  container: {
    marginBottom: 30,
    padding: 0
  }
}));

export default function SingleCategorySmall(props) {
  const classes = styles();
  return (
    <Grid xs={6} sm={4} md={3} className={classes.container}>
      <RouterLink to={userRoutes.CATEGORY + props.id}>
        <LazyLoad offset={500}>
          <img
            src={process.env.PUBLIC_URL + "/images/" + props.img}
            srcSet={props.newImg ? srcSetCreator(props.img) : ""}
            alt={props.name}
            className={classes.img}
          />
        </LazyLoad>
      </RouterLink>
    </Grid>
  );
}
