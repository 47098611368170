import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DuitenIcon from "./DuitenIcon";
import Grid from "@material-ui/core/Grid";
import { Link as RouterLink } from "react-router-dom";
import { userRoutes } from "../routes/Users/constants";
import AddToCartButton from "./AddToCartButton";
import LazyLoad from "react-lazy-load";
import srcSetCreator from "../helpers/srcSetCreator";
import { Translate } from "react-auto-translate";

const styles = makeStyles(theme => ({
  img: {
    height: 175,
    width: "100%",
    borderRadius: 3,
    objectFit: "contain"
  },
  button: {
    color: theme.palette.text.alt,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 3,
    padding: "7px 20px",
    zIndex: 40
  },
  genericIcon: {
    paddingRight: 10
  },
  text: {
    color: theme.palette.text.main,
    marginBottom: 5,
    minHeight: 30
  },
  duitenContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  link: {
    textDecoration: "none",
    zIndex: 30
  },
  container: {
    textAlign: "left",
    padding: 12
  }
}));

export default function SingleProductSmall(props) {
  const classes = styles();

  return (
    <Grid className={classes.container} item xs={6} sm={4} md={3}>
      <RouterLink
        to={userRoutes.PRODUCT + props.id}
        style={{
          textDecoration: "none",
          display: "flex",
          height: "90%",
          flexDirection: "column",
          maxWidth: 250
        }}
      >
        <LazyLoad height={175} width="100%" offset={500}>
          <img
            src={process.env.PUBLIC_URL + "/images/" + props.img}
            srcSet={props.newImg ? srcSetCreator(props.img) : ""}
            className={classes.img}
            alt={props.name}
          />
        </LazyLoad>

        <h3 className={classes.text}>
          <Translate>{props.name}</Translate>
        </h3>
        <div className={classes.duitenContainer}>
          <DuitenIcon />
          <span className={classes.text}>{props.price}</span>
        </div>
      </RouterLink>
      <AddToCartButton
        id={props.id}
        name={props.name}
        price={props.price}
        img={props.img}
        newImg={props.newImg}
        className={classes.addToCartButton}
      />
    </Grid>
  );
}
