import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { PRODUCT } from "../../queries-mutations/products";
import Grid from "@material-ui/core/Grid";
import ImageGallery from "react-image-gallery";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DuitenIcon from "../../components/DuitenIcon";
import parse from "html-react-parser";
import AddToCartButton from "../../components/AddToCartButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import BackButton from "../../components/BackButton";
import { Translate } from "react-auto-translate";

const styles = makeStyles(theme => ({
  galleryContainer: {
    margin: 20
  },
  textContainer: {
    textAlign: "left"
  },
  price: {
    display: "flex",
    alignItems: "center",
    alignContent: "center"
  },
  priceText: {
    marginLeft: 10
  },
  button: {
    color: theme.palette.text.alt,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 3,
    padding: "7px 20px",
    zIndex: 40
  },
  previous: {
    textAlign: "Left",
    padding: 4,
    border: "1px solid rgba(0, 0, 0, 0.5)",
    width: 70,
    fontSize: 14,
    borderRadius: 4
  },
  backIcon: {
    fontSize: 14
  }
}));

export default function SingleProduct() {
  const classes = styles();
  let { id } = useParams();

  const { loading, error, data } = useQuery(PRODUCT, {
    variables: { id: id }
  });
  if (data) {
    const images = [];
    // eslint-disable-next-line array-callback-return
    data.product.images.map(image => {
      images.push({
        original: process.env.PUBLIC_URL + "/images/" + image.location
      });
    });


    return (
      <Grid container spacing={2}>
        <Grid xs={0} md={1} xl={2} />
        <Grid xs={12} md={11} xl={10} className={classes.previousContainer}>
          <BackButton />
        </Grid>

        <Grid xs={0} md={1} xl={2} />
        <Grid xs={12} md={5} xl={4}>
          <Container className={classes.galleryContainer}>
            <ImageGallery
              items={images}
              showThumbnails={false}
              showFullscreenButton={false}
              showPlayButton={false}
              showBullets={true}
              lazyLoad={true}
            />
          </Container>
        </Grid>
        <Grid xs={12} md={5} xl={4} className={classes.textContainer}>
          <h1>
            <Translate>{data.product.name}</Translate>
          </h1>
          <Typography className={classes.price}>
            <DuitenIcon />
            <Typography className={classes.priceText}>
              {data.product.price}
            </Typography>
          </Typography>
          {data.product.stockAmount > 0 && data.product.published && (
            <AddToCartButton
              id={data.product.id}
              price={data.product.price}
              name={data.product.name}
              img={data.product.images[0].location}
            />
          )}
          <Typography className={classes.description}>
            {parse(data.product.description)}
          </Typography>
        </Grid>
      </Grid>
    );
  } else if (error) {
    return (
      <div>
        <h2>
          <Translate>Er ging iets fout, probeer het later opnieuw</Translate>
        </h2>
      </div>
    );
  } else if (loading) {
    return (
      <div>
        <CircularProgress color="primary" />
      </div>
    );
  } else {
    return (
      <div>
        <h2>
          <Translate>Er ging iets fout</Translate>
        </h2>
      </div>
    );
  }
}
