import React from "react";
import IconButton from "@material-ui/core/IconButton";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import SingleProductCart from "./SingleProductCart";
import DuitenIcon from "./DuitenIcon";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { userRoutes } from "../routes/Users/constants";

const headerMenu = makeStyles(theme => ({
  genericIcon: {
    fontSize: 30
  },
  cartText: {
    fontSize: 14,
    paddingLeft: 10
  },
  cartTotal: {
    textAlign: "right"
  },
  cartTotalDuiten: {
    height: 16
  },
  cartCount: {
    position: "absolute",
    right: 0,
    top: 0,
    fontSize: 12,
    height: 18,
    width: 18,
    borderRadius: "50% 50%",
    color: "white",
    backgroundColor: theme.palette.primary.main
  },
  popOver: {
    padding: 12
  }
}));

function MenuCart(state) {
  const classes = headerMenu();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const history = useHistory();

  return (
    <Grid item xs={2}>
      <IconButton color="primary" onClick={handleClick}>
        <ShoppingCartIcon className={classes.genericIcon} />
        <Typography className={classes.cartCount}>
          {state.cart.length}
        </Typography>
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        elevation={3}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Grid container className={classes.popOver}>
          <Grid item xs={12}>
            {state.cart.map((product, index) => (
              <SingleProductCart
                key={product.id}
                name={product.name}
                price={product.price}
                index={index}
                img={product.img}
              />
            ))}
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(userRoutes.CART);
                handleClose();
              }}
            >
              Bekijk & bestel
            </Button>
          </Grid>
          <Grid item xs={6}>
            {state.cart.length > 0 && (
              <Typography className={classes.cartTotal}>
                Totaal{" "}
                {state.cart
                  .map(o => o.price)
                  .reduce((a, c) => {
                    return a + c;
                  })}{" "}
                <DuitenIcon />
              </Typography>
            )}
            {state.cart.length <= 0 && (
              <Typography className={classes.cartText}>
                Er zit niets in je winkelmandje
              </Typography>
            )}
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
}

const mapStateToProps = state => {
  const { cart } = state;
  return { cart };
};

export default connect(mapStateToProps)(MenuCart);
