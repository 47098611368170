import { gql } from "apollo-boost";

export const products = gql`
  {
    products(orderField:"order", orderDirection:"ASC") {
      id
      name
      description
      price
      published
      code
      taxpercent
      order
      stockAmount
      cartSuggestion
      featured
      categories {
        id
        name
      }
      author {
        id
        firstName
        lastName
      }
      stock {
        initial
        current
      }
      images {
        location
        id
        new
      }
    }
  }
`;

export const ADMIN_OVERVIEW_PRODUCTS = gql`
  {
    products(orderField:"order", orderDirection:"ASC") {
      id
      name
      description
      price
      published
      code
      taxpercent
      order
      stockAmount
      cartSuggestion
      featured
      categories {
        id
        name
        products{
          id
      }
      }
      images {
        location
        id
        new
      }
    }
  }
`;

export const CART_SUGGESTIONS = gql`
  {
    cartSuggestions {
      id
      name
      description
      price
      published
      stockAmount
      images {
        location
        id
        new
      }
    }
  }
`;

export const FEATURED_PRODUCTS = gql`
  {
    featuredProducts {
      id
      name
      description
      price
      published
      stockAmount
      images {
        location
        id
        new
      }
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($id: ID!) {
    deleteProduct(id: $id) {
      id
    }
  }
`;

export const PRODUCTS_SHOP = gql`
  {
    productsShop {
      name
      id
      description
      price
      stockAmount
      images {
        location
        id
        new
      }
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation CreateProduct(
    $name: String!
    $description: String!
    $price: Int!
    $authorID: ID!
    $published: Boolean!
    $code: String!
    $taxpercent: Int!
    $order: Int!
    $stockAmount: Int!
    $cartSuggestion: Boolean!
    $featured: Boolean!
  ) {
    createProduct(
      name: $name
      description: $description
      price: $price
      authorID: $authorID
      published: $published
      code: $code
      taxpercent: $taxpercent
      order: $order
      stockAmount: $stockAmount
      cartSuggestion: $cartSuggestion
      featured: $featured
    ) {
      id
      name
      description
      price
      published
      code
      taxpercent
      order
      categories {
        id
        name
      }
      author {
        id
        firstName
        lastName
      }
      stock {
        initial
        current
      }
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct(
    $id: ID!
    $name: String!
    $description: String!
    $price: Int!
    $published: Boolean!
    $code: String
    $taxpercent: Int!
    $order: Int!
    $stockAmount: Int!
    $cartSuggestion: Boolean!
    $featured: Boolean!
  ) {
    updateProduct(
      id: $id
      name: $name
      description: $description
      price: $price
      published: $published
      code: $code
      taxpercent: $taxpercent
      order: $order
      stockAmount: $stockAmount
      cartSuggestion: $cartSuggestion
      featured: $featured
    ) {
      id
      name
      description
      price
      published
      code
      taxpercent
      order
      stockAmount
      categories {
        id
        name
      }
      author {
        id
        firstName
        lastName
      }
      stock {
        initial
        current
      }
    }
  }
`;

export const ADD_PRODUCT_TO_CATEGORY = gql`
  mutation addProductToCategory($productId: ID!, $categoryId: ID!) {
    addProductToCategory(productId: $productId, categoryId: $categoryId) {
      name
      id
    }
  }
`;

export const REMOVE_PRODUCT_FROM_CATEGORY = gql`
  mutation removeProductFromCategory($productId: ID!, $categoryId: ID!) {
    removeProductFromCategory(productId: $productId, categoryId: $categoryId) {
      name
      id
    }
  }
`;

export const CREATE_IMAGE = gql`
  mutation createImage($location: String!) {
    createImage(location: $location) {
      id
      location
    }
  }
`;

export const ADD_IMAGE_TO_PRODUCT = gql`
  mutation addImageToProduct($productId: ID!, $imageId: ID!) {
    addImageToProduct(productId: $productId, imageId: $imageId) {
      id
      name
    }
  }
`;

export const REMOVE_IMAGE_FROM_PRODUCT = gql`
  mutation removeImageFromProduct($productId: ID!, $imageId: ID!) {
    removeImageFromProduct(productId: $productId, imageId: $imageId) {
      id
      name
    }
  }
`;

export const ADD_IMAGE_TO_CATEGORY = gql`
  mutation addImageToCategory($categoryId: ID!, $imageId: ID!) {
    addImageToCategory(categoryId: $categoryId, imageId: $imageId) {
      id
      name
    }
  }
`;

export const REMOVE_IMAGE_FROM_CATEGORY = gql`
  mutation removeImageFromCategory($categoryId: ID!) {
    removeImageFromCategory(categoryId: $categoryId) {
      id
      name
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation createCategory(
    $name: String!
    $description: String!
    $published: Boolean!
    $order: Int
    $authorID: ID!
    $parentID: ID
  ) {
    createCategory(
      name: $name
      description: $description
      published: $published
      order: $order
      authorID: $authorID
      parentID: $parentID
    ) {
      id
      name
      description
      order
      published
      parent {
        id
        name
      }
      author {
        firstName
        lastName
      }
      products {
        name
        id
      }
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation updateCategory($id: ID!, $name: String!, $description: String!, $order: Int, $published:Boolean!) {
    updateCategory(id: $id, name: $name, description: $description, order:$order, published: $published) {
      id
      name
      description
      order
      published
      parent {
        id
        name
      }
      author {
        firstName
        lastName
      }
      products {
        name
        id
      }
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: ID!) {
    deleteCategory(id: $id) {
      id
    }
  }
`;

export const CONNECT_CATEGORY = gql`
  mutation connectCategory($id: ID!, $parentId: ID!) {
    connectCategory(id: $id, parentId: $parentId) {
      id
    }
  }
`;

export const DISCONNECT_CATEGORY = gql`
  mutation disconnectCategory($id: ID!) {
    disconnectCategory(id: $id) {
      id
    }
  }
`;

export const categoriesMinimal = gql`
  {
    categories(orderField:"order", orderDirection:"ASC") {
      id
      name
      published
      order
    }
  }
`;

export const categories = gql`
  {
    categories(orderField:"order", orderDirection:"ASC")  {
      id
      name
      description
      published
      order
      parent {
        id
        name
      }
      author {
        firstName
        lastName
      }
      products {
        name
        id
      }
      image {
        id
        location
        new
      }
    }
  }
`;

export const CATEGORY = gql`
  query category($id: ID!) {
    category(id: $id) {
      id
      name
      description
      published
      parent {
        name
        id
      }
      image {
        location
        id
        new
      }
      products {
        name
        id
        published
        order
        stockAmount
        images {
          location
          id
          new
        }
        price
      }
    }
  }
`;

export const PRODUCT = gql`
  query product($id: ID!) {
    product(id: $id) {
      id
      name
      price
      stockAmount
      published
      images {
        location
        id
        new
      }
      description
      code
    }
  }
`;

export const SEARCH_PRODUCTS = gql`
  query searchProducts($q: String!) {
    searchProducts(q: $q) {
      name
      price
      images {
        location
        id
        new
      }
      description
      code
      id
    }
  }
`;
