import React,  { useState } from "react";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { makeStyles,createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { IconButton } from '@material-ui/core';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Papa from "papaparse";


export default function CustomersTable(props) {

  const [state, setState] = useState({
    duitenleft : false,
    selecteduser:[]
  })

  const [modal, setModal] = React.useState();

  const handleCheckChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const [isShown, setIsShown] = useState(false);
  
  const handleCloseNewModal = () => {
    setModal(<div />);
    setTimeout(function() {
      //refetch();
    }, 500);
  };


  const styles = makeStyles(theme => ({

    hightlight: {
      background: "yellow",
      padding:"20px"
    },
    duiten:{
      padding:"20px"
    },
    right:{
      float: "right",
    },
    left:{
      float: "left",
    },
    btn_margin:{
      margin:"0 0 15px",
    },
    clear:{
      clear: "both",
    }
  }));

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        filterType: "textField"
      }
    },
    {
      name: "firstName",
      label: "Voornaam",
      options: {
        filterType: "textField"
      }
    },
    {
      name: "insertion",
      label: "Tussenvoegsel",
      options: {
        filterType: "textField"
      }
    },
    {
      name: "lastName",
      label: "Achternaam",
      options: {
        filterType: "textField"
      }
    },
    {
      name: "email",
      label: "email",
      options: {
        display: false,
        filterType: "textField"
      }
    },
    {
      name: "code",
      label: "Code",
      options: {
        filterType: "textField"
      }
    },
    {
      name: "gender",
      label: "geslacht",
      options: {
        filterType: "multiselect"
      }
    },
    {
      name: "duiten",
      label: "Duiten",
      options: {
        filterType: "textfield",

      }
    },
    {
      name: "aanpassen",
      label:"Aanpassen"
    },
    {
      name: "producten",
      label:"Producten"
    },
  ];

  let tableData = [];
  let csvData = [];

  const onRowClick = selected => {
    const clickedCustomer = props.employees.find(
      user => user.id === selected[0]
    );
    props.onRowClick(clickedCustomer);
  };

  const oncheckboxClick = selected => {
    console.log(selected[0]);
    // const clickedCustomer = props.employees.find(
    //   user => user.id === selected[0]
    // );
  };

  const Onproductclick = selected => {
    const clickedCustomer = props.employees.find(
      user => user.id === selected[0]
    );
    props.Onproductclick(clickedCustomer);
  };

  const MultiProduct = selected => {
    props.MultiProduct(state.selecteduser);
  };

  const classes = styles();

  props.employees.forEach(function(employee) {
    if(!state.duitenleft || employee.duiten > 0){
    tableData.push({
      id: employee.id,
      firstName: employee.firstName,
      insertion: employee.insertion,
      lastName: employee.lastName,
      email: employee.email,
      gender: employee.gender,
      code:
       (
          <span>
            <IconButton onClick={() => {navigator.clipboard.writeText(employee.code)}}>
              <FileCopyIcon />
            </IconButton>
            {employee.code}
          </span>
      ), // csv use  employee.code
      duiten: employee.duiten > 0 ? <span className={classes.hightlight}>{employee.duiten}</span> : <span className={classes.duiten}>{employee.duiten}</span>,
      aanpassen: <Button
          variant="contained"
          color="primary"
          onClick={() => {
        
            props.onRowClick(employee);
          }}
      >
        Aanpassen
      </Button>,
      producten: employee.duiten > 0 ? <Button variant="contained" color="primary"
      onClick={()=> {
        props.Onproductclick(employee);
      }}>Producten toewijzen</Button>:''
    });
    csvData.push({
      id: employee.id,
      firstName: employee.firstName,
      insertion: employee.insertion,
      lastName: employee.lastName,
      email: employee.email,
      gender: employee.gender,
      code:employee.code, // csv use  employee.code
      duiten: employee.duiten   
    });
  }
  });

  const options = {
    filterType: "checkbox",
    selectableRows: "multiple",
    selectableRowsHeader: true,
    selectableRowsOnClick: true,
    onDownload: () => {

      const csvContent = Papa.unparse(csvData);
  
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "tableDownload.csv";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    onRowsSelect: (dataIndex, allRows) => {
      state.selecteduser =[];
      const dataToState = allRows.map((item) => {
        return item;
      });
      var mySelectedRows = dataToState;
      mySelectedRows.map(data=>{
        state.selecteduser.push(tableData[data.dataIndex]);
        console.log(state.selecteduser);
      })
    },
  };
  console.log(tableData, "tableData");
  return (
    <MuiThemeProvider> 
     <FormControlLabel
              className={[classes.input, classes.lastInput,classes.right]}
              control={
                <Switch
                  checked={state.duitenleft}
                  onChange={handleCheckChange}
                  name="duitenleft"
                  label="Gebruikers met duiten links"
                />
              }
              label="Gebruikers met duiten links"
            />
      <Button variant="contained" color="primary" className={[classes.input, classes.lastInput,classes.left, classes.btn_margin]}
      onClick={()=> {
        props.MultiProduct(state.selecteduser);
      }}>Producten toewijzen</Button>  
    <MUIDataTable
      className={[classes.clear]}
      title={"Werknemers"}
      data={tableData}
      columns={columns}
      options={options}
    />
    </MuiThemeProvider>
  );
}
