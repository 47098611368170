import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const styles = makeStyles(theme => ({
  image: {
    maxWidth: 150,
    maxHeight: 150,
    margin: 10
  },
  prodimage: {
    maxWidth: 120,
    maxHeight: 120,
  },
  imagesContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  deleteContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    color: "white",
    backgroundColor: "red",
    borderRadius: "50%",
    padding: 0,
    "&:hover": {
      backgroundColor: "white",
      color: "red"
    }
  },
  imageContainer: {
    position: "relative"
  }
}));

export default function AdminImageDisplay(props) {
  const classes = styles();
  const [state, setState] = useState({
    images: props.images
  });

  const hideImage = imageID => {
    let deletedImages = state.images.map(image => {
      if (image.id === imageID) {
        return Object.assign({}, image, { deleted: true });
      }
      return image;
    });
    setState({ images: deletedImages });
  };

  return (
    <div className={classes.imagesContainer}>
      {props.assigntable ? ( state.images[0] ? (
         <div className={classes.imageContainer}>
            <img
              className={classes.prodimage}
              src={process.env.PUBLIC_URL + "/images/" + state.images[0].location}
              alt=""
              id={state.images[0].id}
            />
      </div>) : <div />
 ) : (state.images.map(image =>
        image.deleted ? (
          <div />
        ) : (
          <div className={classes.imageContainer}>
            <IconButton
              aria-label="delete"
              className={classes.deleteContainer}
              onClick={event => {
                event.preventDefault();
                hideImage(image.id);
                props.removeImage(image.id);
              }}
            >
              <HighlightOffIcon />
            </IconButton>
            <img
              className={classes.image}
              src={process.env.PUBLIC_URL + "/images/" + image.location}
              alt=""
              id={image.id}
            />
          </div>
        )
      ))}
    </div>
  );
}
