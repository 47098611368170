import React from "react";
import Button from "@material-ui/core/Button";
import { editModalStyle } from "../styles/editModalStyle";
import { Translate } from "react-auto-translate";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function ConfirmationRequiredButton(props) {
  const classes = editModalStyle();

  return (
    <div>
      {props.initiated ? (
        <div>
          <h3>{props.confirmationMessage}</h3>
          <Button
            variant="contained"
            color="primary"
            className={[classes.input]}
            size="large"
            onClick={props.cancel}
          >
            <Translate>{props.cancelMessage}</Translate>
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={[classes.input, classes.marginLeft]}
            size="large"
            onClick={props.confirm}
            disabled={props.disabled}
          >
            <Translate>{props.confirmMessage}</Translate>
          </Button>
        </div>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          className={[classes.input]}
          size="large"
          onClick={props.initiate}
        >
          <Translate>{props.initialMessage}</Translate>
        </Button>
      )}
      {props.disabled ? (
        <div>
        {/* <CircularProgress className={[classes.input]} color="primary" /> */}
        <CircularProgress 
            className={typeof classes.input === 'string' ? classes.input : classes.input.join(' ')}
           color="primary" />


      </div> ) : (<div></div>)}
    </div>
  );
}
