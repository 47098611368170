import React from "react";
import { adminMenuStyles } from "../../styles/AdminMenu";
import AdminMenu from "../../components/AdminMenu";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Producten from "./Producten";
import Categorieen from "./Categorieen";
import Bedrijven from "./Bedrijven";
import Gebruikers from "./Gebruikers";
import ImageUploadNotification from "../../components/ImageUploadNotification";
import SingleBedrijf from "./SingleBedrijf";
import { connect } from "react-redux";
import Login from "./Login";
import Vragen from "./Vragen";
import OrdersPerDag from "./OrdersPerDag";

function Admin(props) {
  const adminMenuClasses = adminMenuStyles();
  let match = useRouteMatch();

  return (
    <div className="container">
      <div className={adminMenuClasses.root}>
        <AdminMenu title="Admin menu" />
        <CssBaseline />
        <main className={adminMenuClasses.content}>
          <div className={adminMenuClasses.toolbar} />
          <ImageUploadNotification />
          {props.adminAuth.token ? (
            <Switch>
              <Route path={`${match.path}/producten`}>
                <Producten />
              </Route>
              <Route path={`${match.path}/categorieen`}>
                <Categorieen />
              </Route>
              <Route path={`${match.path}/orders`}>
                <OrdersPerDag />
              </Route>
              <Route path={`${match.path}/bedrijven`}>
                <Bedrijven />
              </Route>
              <Route path={`${match.path}/bedrijf/:id`}>
                <SingleBedrijf />
              </Route>
              <Route path={`${match.path}/gebruikers`}>
                <Gebruikers />
              </Route>
              <Route path={`${match.path}/vragen`}>
                <Vragen />
              </Route>
            </Switch>
          ) : (
            <Login />
          )}
        </main>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  const { adminAuth } = state;
  return { adminAuth };
};
export default connect(mapStateToProps)(Admin);
