import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import VragenFormulier from "../../components/VragenFormulier";
import { Translate } from "react-auto-translate";

const styles = makeStyles(theme => ({
  container: {
    textAlign: "left"
  },
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));

export default function Over() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const classes = styles();
  return (
    <Grid container>
      <Grid Grid xs={0} md={2} />
      <Grid Grid xs={12} md={8} className={classes.container}>
        <h1>
          <Translate>Klantenservice</Translate>
        </h1>
        <p>
          <Translate>
            Een goede service, dat is waar wij veel waarde aan hechten. Om die
            reden hebben wij de belangrijkste vragen uitgesplitst in
            onderstaande rubrieken. Klik op één van de rubrieken voor meer
            informatie:
          </Translate>
        </p>
        <Accordion
          expanded={expanded === "algemeen"}
          onChange={handleChange("algemeen")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              Zeg het met Duiten
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                <Translate>Contactgegevens:</Translate>
              </p>
              <List>
                <p>
                  <Translate>Kantoor/factuuradres</Translate>
                </p>
                <p>Raaijmakersstraat 21</p>
                <p>4891 NC Rijsbergen</p>
              </List>
              <List>
                <p>
                  <Translate>Magazijnadres</Translate>
                </p>
                <p>Laguitensebaan 70</p>
                <p>4891 XR Rijsbergen</p>
              </List>
              <List>
                <p>
                  <Translate>Telefonisch bereikbaar</Translate>
                </p>
                <p>Marianne Schlamann 06-11473755 (inkoop & verkoop)</p>
                <p>Bart Manders 06-11523008 (logistiek & retouren)</p>
              </List>
              <List>
                <p>Mailadres: info@zeghetmetduiten.nl</p>
                <p>Webadres: www.zeghetmetduiten.nl</p>
              </List>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "Bestellen"}
          onChange={handleChange("Bestellen")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              <Translate>Bestellen</Translate>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                <Translate>
                  Hoera! Je hebt Duiten ontvangen en je mag gaan shoppen!
                </Translate>
              </p>
              <p>
                <Translate>
                  Door op de overzichtsfoto’s te klikken kom je uit bij de
                  artikelen op die online-kraam. Eigenlijk werkt het net zo als
                  op een echte geschenkenmarkt. Je shopt bij de diverse kramen
                  en maakt een keuze uit de producten. Door op een bepaald
                  product te klikken zie je vaak meerdere foto’s en extra
                  informatie over een product. Klik op het winkelmandje als je
                  het artikel wilt ontvangen.
                </Translate>
              </p>
              <p>
                <Translate>
                  Als je klaar bent met shoppen ga je afrekenen. Dit doe je door
                  de unieke code in te voeren die je van je werkgever of relatie
                  ontvangen hebt.
                </Translate>
              </p>
              <p>
                <Translate>
                  Als je dat gedaan hebt ontvang je een bevestigingsmail van
                  jouw aankopen.
                </Translate>
              </p>
              <p>
                <Translate>
                  Wij doen er alles aan om voldoende voorraad van alle artikelen
                  te hebben. Maar het kan voorkomen dat een bepaald product
                  uitverkocht is, terwijl het nog wel op de geschenkenmarkt
                  stond. Dit kan gebeuren omdat er dan door veel mensen in één
                  keer geshopt wordt.
                </Translate>
              </p>
              <p>
                <Translate>
                  Als een door jou gekozen artikel uitverkocht is en niet meer
                  na leverbaar, zullen wij contact met je zoeken om een
                  alternatief artikel te bespreken.
                </Translate>
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "Verzenden"}
          onChange={handleChange("Verzenden")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              <Translate>Verzenden</Translate>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                <Translate>
                  Als de shoptermijn er voor jouw organisatie op zit (meestal is
                  dit twee weken), dan gaan wij jouw geshopte artikelen bij
                  elkaar zoeken en ervoor zorgen dat deze artikelen goed verpakt
                  worden. Vervolgens worden alle pakketten van alle medewerkers
                  van de organisatie waar jij voor werkt in één levering bij
                  jouw bedrijf gebracht. Op een bepaalde datum zal de
                  organisatie waar jij voor werkt de pakketten uitreiken, hier
                  wordt vaak een speciaal moment voor gekozen. Mocht het niet
                  duidelijk zijn wanneer dit is, neem dan contact op met je
                  werkgever.
                </Translate>
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "Foutief"}
          onChange={handleChange("Foutief")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              <Translate>Foutieve zending</Translate>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                <Translate>
                  Je ontvangt je pakket maar het product dat je besteld hebt zit
                  er niet in of er zit zelfs een ander product in? Dat is
                  natuurlijk niet de bedoeling en vooral heel erg vervelend.
                  Indien je een pakket met een foutief product ontvangt of een
                  gekozen product zit er niet bij, vragen we je onderstaand
                  formulier in te vullen. Wij gaan uiteraard zo snel mogelijk op
                  zoek naar een oplossing en zullen contact met je opnemen voor
                  verdere stappen. We leveren snel het bestelde product, of gaan
                  indien dit niet mogelijk is samen met jou op zoek naar een
                  alternatief.
                </Translate>
              </p>
              <VragenFormulier />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "Schade"}
          onChange={handleChange("Schade")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              <Translate>Schade en retourneren</Translate>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                <Translate>
                  Wij proberen er alles aan te doen om het gekozen artikel zo
                  goed en veilig mogelijk te leveren. Het kan gebeuren dat er
                  tijdens het transport iets kapot gaat. Zichtbare gebreken of
                  tekorten dien je binnen 7 dagen na ontvangst schriftelijk aan
                  Zeg het met Duiten te melden. Volg hiervoor de volgende
                  procedure:
                </Translate>
              </p>
              <p>
                <Translate>
                  Vul het onderstaand formulier in. Je krijgt dan van ons zo
                  snel mogelijk reactie met vervolgstappen. Als het gekozen
                  product nog voorradig is, sturen wij je een nieuw exemplaar.
                  Indien het gekozen product niet meer voorradig is, gaan we in
                  overleg met jou op zoek naar een goed alternatief.
                </Translate>
              </p>
              <p>
                <Translate>
                  Niet-zichtbare gebreken dienen binnen 5 werkdagen na
                  ontdekking, doch uiterlijk binnen 1 maand na levering aan Zeg
                  het met Duiten schriftelijk te worden gemeld.
                </Translate>
              </p>
              <p>
                <Translate>
                  Uitbetaling van kapotte artikelen is niet mogelijk.
                </Translate>
              </p>
              <p>
                <Translate>
                  Retouren kunnen alleen geaccepteerd worden door het juist
                  invullen van het onderstaand formulier en de daarna volgende
                  instructies vanuit Zeg het met Duiten. Soms is het belangrijk
                  dat het kapotte artikel retour komt, als dat het geval is
                  ontvang je van ons een verzendoptie.
                </Translate>
              </p>
              <VragenFormulier />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "Garantie"}
          onChange={handleChange("Garantie")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              <Translate>Garantie en defecten</Translate>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                <Translate>
                  Niet-zichtbare gebreken dien je binnen 5 werkdagen na
                  ontdekking, doch uiterlijk binnen 1 maand na levering aan Zeg
                  het met Duiten schriftelijk te worden gemeld. Je kunt hiervoor
                  gebruik maken van het serviceformulier welke je hier onder
                  kunt vinden.
                </Translate>
              </p>
              <p>
                <Translate>
                  Wanneer bovengenoemde het geval is, vragen we je contact met
                  ons op te nemen. Volg hiervoor de volgende procedure:
                </Translate>
              </p>
              <p>
                <Translate>
                  Vul onderstaand formulier in. Je krijgt dan van ons zo snel
                  mogelijk reactie met vervolgstappen. Als het gekozen product
                  nog voorradig is, sturen wij je een nieuw exemplaar toe.
                  Indien het gekozen product niet meer voorradig is, gaan we in
                  overleg met jou op zoek naar een goed alternatief.
                </Translate>
              </p>
              <p>
                <Translate>
                  Uitbetaling van terug-gestuurde artikelen is niet mogelijk.
                </Translate>
              </p>
              <p>
                <Translate>
                  Soms is het belangrijk dat het kapotte artikel retour komt.
                  Als dat het geval is ontvang je van ons een verzendoptie.
                </Translate>
              </p>
              <p>
                <Translate>
                  Ongeoorloofd gebruik, slijtage door gebruik én wijzigingen aan
                  het product die door jezelf zijn aangebracht, vallen buiten de
                  garantieregeling.
                </Translate>
              </p>
              <VragenFormulier />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "Veiligheid"}
          onChange={handleChange("Veiligheid")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              <Translate>Veiligheid en Privacy</Translate>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                <Translate>Verklaring gebruikte termen:</Translate>
              </p>
              <p>
                ZHMD = Zeg het met Duiten <br />
                <Translate>
                  Shopper = werknemer van opdrachtgever die komt shoppen op de
                  fysieke markt of op de online markt
                </Translate>
              </p>
              <p>
                <Translate>
                  Bij ZHMD werken slechts twee personen (tevens eigenaren) die
                  eventueel met de persoonsgegevens van werknemers van onze
                  klanten in aanraking komen en deze verwerken.
                </Translate>
              </p>
              <p>
                <Translate>
                  Welke persoonsgegevens worden gebruikt en waarom?
                </Translate>
                <br />
                <Translate>
                  ZHMD krijgt bij fysieke markten geen persoonsgegevens van
                  shoppers. Het enige moment dat er gegevens verstrekt worden is
                  wanneer shoppers niet op de fysieke markt komen maar op de
                  online markt gaan shoppen. ZHMD maakt voor elke shopper een
                  account aan. De benodigde / gebruikte gegevens bestaan dan
                  uit: voor- en achternaam, e-mailadres (kan ook privéadres
                  zijn) en in uitzonderlijke gevallen ook het woonadres van de
                  shopper. Dit is een optie die wij per klant eventueel aan- en
                  uit kunnen zetten. Wij gebruiken deze gegevens om de shoppers
                  uit te nodigen voor de markt (e-mail) en om het juiste pakket
                  aan de juiste persoon te koppelen. Persoonsgegevens worden
                  nooit en te nimmer gebruikt voor persoonlijke benadering om
                  andere redenen (bijvoorbeeld nieuwbrieven of andere
                  marketingdoeleinden) dan de online markt.
                </Translate>
                <br />
                <Translate>
                  Deze gegevens worden in een afgeschermde opslagplaats bewaard.
                  Na verwerking en de reclamatieperiode worden deze gegevens
                  verwijderd.
                </Translate>
              </p>
              <p>
                <Translate>
                  Er zijn geen derden die met deze gegevens werken. Slechts
                  personeelsleden van ZHMD zien deze namen bij het verzamelen
                  van pakketten nadat de shopper deze heeft geshopt in de online
                  markt. Medewerkers van ZHMD zijn altijd op de hoogte van de
                  AVG regels.
                </Translate>
              </p>
              <p>
                <Translate>
                  Op het moment dat shoppers inloggen op de webshop geven zij
                  aan ZHMD toestemming om gebruik te maken van hun naam en
                  e-mailadres. Dit wordt duidelijk gemeld op deze pagina.
                </Translate>
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
