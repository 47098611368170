import React from "react";
import { connect } from "react-redux";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { addToCart } from "../actions";
import { Typography } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { Translate } from "react-auto-translate";

const styles = makeStyles(theme => ({
  button: {
    color: theme.palette.text.alt,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 3,
    padding: "7px 20px",
    zIndex: 40
  },
  text: {
    "@media (max-width: 768px)": {
      fontSize: 16
    }
  },
  genericIcon: {
    "@media (max-width: 768px)": {
      display: "none"
    }
  }
}));

const AddToCartButton = props => {
  const classes = styles();

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [openAlert, setOpenAlert] = React.useState(false);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        if (props.cart.filter(obj => obj.id === props.id).length >= 2) {
          setOpenAlert(true);
        } else {
          props.dispatch(
            addToCart(
              props.id,
              props.name,
              props.price,
              props.img,
              props.newImg ? props.newImg : false
            )
          );
          setOpen(true);
        }
      }}
    >
      <Button
        size="large"
        className={[classes.button, props.className]}
        type="submit"
      >
        <ShoppingCartIcon className={classes.genericIcon} />
        <Typography className={classes.text}>
          <Translate>IN WINKELMANDJE</Translate>
        </Typography>
      </Button>
      <Snackbar
        open={openAlert}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity="warning">
          <Translate>
            Je kan maximaal 2 maal hetzelfde product aan je winkelmandje
            toevoegen
          </Translate>
        </Alert>
      </Snackbar>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          <Translate>{props.name} is toegevoegd aan je winkelmandje</Translate>
        </Alert>
      </Snackbar>
    </form>
  );
};

const mapStateToProps = state => {
  const { cart } = state;
  return { cart };
};

export default connect(mapStateToProps)(AddToCartButton);
