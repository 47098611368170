import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { BottomNavigation } from "@material-ui/core";
import MainLogo from "./MainLogo";
import { Link as RouterLink } from "react-router-dom";
import { userRoutes } from "../routes/Users/constants";
import Typography from "@material-ui/core/Typography";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Translate } from "react-auto-translate";

const styles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    marginTop: 30,
    paddingTop: 20,
    paddingBottom: 20,
    display: "flex",
    flexDirection: "column"
  },
  innerContainer: {},
  text: {
    color: theme.palette.text.alt,
    textDecoration: "none"
  },
  textLeft: {
    color: theme.palette.text.alt,
    textAlign: "left"
  },
  textRight: {
    color: theme.palette.text.alt,
    textAlign: "right"
  },
  socialIcon: {
    color: theme.palette.text.alt,
    backgroundColor: theme.palette.secondary.main,
    padding: 5,
    borderRadius: "50%",
    marginLeft: 5,
    marginRight: 5
  },
  hideOnMobile: {
    "@media (max-width: 768px)": {
      display: "none"
    }
  }
}));

export default function Footer() {
  const classes = styles();

  return (
    <BottomNavigation  className={classes.container}>
      <Grid container  className={classes.innerContainer}>
        <Grid item xs={2} component={RouterLink} to={userRoutes.SHOP}>
          <MainLogo
            style={{
              height: 50
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.textLeft}>
            ©2020 zeghetmetduiten.nl
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            component={RouterLink}
            to={userRoutes.OVER}
            className={[classes.text, classes.hideOnMobile]}
          >
            <Translate>Over ons en veelgestelde vragen</Translate>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <a href="https://www.facebook.com/Zeghetmetduiten/">
            <FacebookIcon className={classes.socialIcon} />
          </a>
          <a href="https://www.linkedin.com/company/zeg-het-met-duiten/">
            <LinkedInIcon className={classes.socialIcon} />
          </a>
          <a href="https://www.instagram.com/zeghetmetduiten">
            <InstagramIcon className={classes.socialIcon} />
          </a>
        </Grid>
      </Grid>
    </BottomNavigation>
  );
}
