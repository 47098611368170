import React from "react";
import Grid from "@material-ui/core/Grid";
import FullWidthImage from "../../components/FullWidthImage";
import loggedInHeader from "../../assets/Banner_home_shop.jpg";
import { makeStyles } from "@material-ui/core/styles";
import MainCategoryOverview from "../../components/MainCategoryOverview";
import BestSellers from "../../components/BestSellers";

const styles = makeStyles(theme => ({
  imageContainer: {
    margin: "10px 25px"
  },
  itemContainer: {
    marginTop: 50
  }
}));

export default function UsersLanding(props) {
  const classes = styles();
  return (
    <Grid container>
      <Grid xs={12} className={classes.imageContainer}>
        <FullWidthImage src={loggedInHeader} overlay={false} />
      </Grid>
      <Grid xs={0} md={1} />
      <Grid xs={12} md={10}>
        <MainCategoryOverview />
        <BestSellers />
      </Grid>
    </Grid>
  );
}
