import React from "react";
import MUIDataTable from "mui-datatables";

export default function ProductenTable(props) {
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        filterType: "textField"
      }
    },
    {
      name: "order",
      label: "order",
      options: {
        filterType: "textField"
      }
    },
    {
      name: "name",
      label: "Naam",
      options: {
        filterType: "textField"
      }
    },
    {
      name: "code",
      label: "Code",
      options: {
        filterType: "textField"
      }
    },
    {
      name: "description",
      label: "Beschrijving",
      options: {
        filter: false,
        display: false
      }
    },
    {
      name: "categories",
      label: "Categorieen",
      options:{
        filterType: 'dropdown'
      }
    },
    {
      name: "price",
      label: "Prijs",
      options: {
        filterType: "multiselect"
      }
    },
    {
      name: "stockAmount",
      label: "Voorraad",
      options: {
        filterType: "multiselect"
      }
    },
    {
      name: "cartSuggestion",
      label: "Winkelwagen suggestie",
      options: {
        filterType: "multiselect"
      }
    },
    {
      name: "featured",
      label: "Uitgelicht",
      options: {
        filterType: "multiselect"
      }
    }
  ];

  let tableData = [];

  props.data.forEach(function(product) {
    let categories = [];
    if(product.categories){
    product.categories.map(category => {
      categories.push(category.name + ", ");
    });
  }
    tableData.push({
      id: product.id,
      order:product.order,
      name: product.name,
      description: product.description.replace(/(<([^>]+)>)/ig, ''),
      categories: categories,
      price: product.price,
      code: product.code,
      stockAmount: product.stockAmount,
      cartSuggestion: product.cartSuggestion ? "Ja" : "Nee",
      featured: product.featured ? "Ja" : "Nee"
    });
  });

  const onRowClick = selected => {
    const selectedProduct = props.data.find(
      product => product.id === selected[0]
    );
    props.onRowClick(selectedProduct);
  };

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    onRowClick: onRowClick
  };

  return (
    <MUIDataTable
      title={props.title}
      data={tableData}
      columns={columns}
      options={options}
    />
  );
}
