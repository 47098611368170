import React from "react";
import { connect } from "react-redux";
import { useQuery } from "@apollo/react-hooks";
import { ORDERS_BY_CUSTOMER_CODE } from "../../queries-mutations/customers";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import SingleOrderOverview from "../../components/SingleOrderOverview";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { Translate } from "react-auto-translate";

function BestellingGeslaagd(props) {
  let code = props.userInformation.code;

  const { loading, error, data } = useQuery(ORDERS_BY_CUSTOMER_CODE, {
    variables: { code: code }
  });

  if (error) {
    return (
      <Paper>
        <h2>
          <Translate>Er ging iets fout, probeer het later weer</Translate>
        </h2>
      </Paper>
    );
  } else if (loading) {
    return (
      <Paper>
        <CircularProgress />
      </Paper>
    );
  } else if (data) {
    console.log("BestellingGeslaagd data =>",data.customerByCode);
    let tooLate;
    let endDate = moment(data.customerByCode.company.endDate, "YYYY-MM-DD");
    if (moment().isAfter(endDate)) {
      tooLate = true;
    }
    console.log("data",data.customerByCode )
    // tooLate = true; // vasanth
    console.log("tooLate",tooLate)
    return (
      <Grid container>
        {/* <Grid xs={0} md={1} /> */}
        <Grid item xs={false} md={1} />
        <Grid item xs={12} md={10}>
          {tooLate && (
            <div>
              <h2>
                <Translate>
                  De shopperiode zit er op. De online shop is om die reden nu
                  gesloten.
                </Translate>
              </h2>
            </div>
          )}
          {data.customerByCode.order.length > 0 && (
            <div>
              <h2>
                <Translate>Je hebt besteld!</Translate>
              </h2>
              <Typography>
                <Translate>
                  Je bestelling wordt binnenkort bij je werkgever geleverd
                  (tenzij anders afgesproken).
                </Translate>
              </Typography>
              <Typography>
                <Translate>
                  De volgende artikelen gaan we voor je inpakken:
                </Translate>
              </Typography>
              {data.customerByCode.order.map(data => (
                <SingleOrderOverview 
                key={data.id } 
                data={data} />
              ))}
            </div>
          )}
        </Grid>
        {/* <Grid xs={0} md={1} /> */}
        <Grid item xs={false} md={1} />
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  const { userInformation } = state;
  return { userInformation };
};

export default connect(mapStateToProps)(BestellingGeslaagd);
