import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

export const styles = makeStyles((theme) => ({
  container: {
    position: "fixed",
    bottom: 10,
    backgroundColor: "white",
    zIndex: 50,
    left: "50%",
    transform: "translateX(-50%)",
  },
  loadingBar: {
    height: 10,
    borderRadius: 5,
  },
  text: {
    paddingLeft: 15,
    paddingRight: 15,
  },
}));

export default function ImageUploadNotification() {
  const classes = styles();
  const { promiseInProgress } = usePromiseTracker();
  return (
    <div>
      {promiseInProgress === true ? (
        <Paper className={classes.container} elevation={3}>
          <Typography className={classes.text}>
            <h3>Even bezig... verlaat of ververs de pagina niet!</h3>
          </Typography>
          <LinearProgress className={classes.loadingBar} />
        </Paper>
      ) : null}
    </div>
  );
}
